var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":'التقارير',"description":'تفاصيل حساب المورد.'}}),_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('searchInput',{staticClass:"col-md-3",attrs:{"title":"المورد","plaseholder":"اختر موؤد","show":'name',"refrance":'id',"value":_vm.params.supplier_id,"values":_vm.suppliers},model:{value:(_vm.params.supplier_id),callback:function ($$v) {_vm.$set(_vm.params, "supplier_id", $$v)},expression:"params.supplier_id"}}),_c('dateFilter',{staticClass:"col-md-3"}),_c('actionButtons',{staticClass:"col-md-3"})],1)])]),_c('div',{attrs:{"id":"reportShow"}},[_c('reportHeader',{attrs:{"title":"اجمالي حساب المورد","details":[
            {title: 'اسم المورد', value : _vm.supplierName }
          ]}}),_c('reportTable',{attrs:{"header":[
              { colspan: 8, value: 'الرصيد الأفتتاحي' },
              {
                colspan: 1,
                value: _vm.startBalance,
              },
            ],"emptyTableText":'لايوجد بيانات حاليا',"emptyTableSubText":'جرب اضافة البعض واعد المحاولة',"cloumns":[
             { column: 'code', title: 'الكود', type: 'reportCode' },
              { column: 'date', title: 'التاريخ', type: 'text' },
              { column: 'type', title: 'النوع', type: 'reportType' },
              { column: 'statement', title: 'البيان', type: 'statement' },
              { column: 'quantity', title: 'الكمية', type: 'textOrEmpty' },
              {
                column: 'price',
                title: 'السعر',
                type: 'textOrEmpty',
              },
              {
                column: 'debit',
                title: 'علية',
                type: 'textOrEmpty',
              },
              {
                column: 'creditor',
                title: 'له',
                type: 'textOrEmpty',
              },
              {
                column: 'new_balance',
                title: 'الرصيد',
                type: 'text',
              },
            ],"footer":[
              { colspan: 6, value: 'الرصيد النهائي' },
              { colspan: 1, value: _vm.debit },
              { colspan: 1, value: _vm.creditor },
              {
                colspan: 1,
                value: _vm.endBalance,
              },
            ],"tafqeet":_vm.tafqeet}})],1)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-edit"}),_vm._v(" خيارات التقرير ")])])
}]

export { render, staticRenderFns }